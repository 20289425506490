import { defineStore } from "pinia";
import axios from "axios";

export const useSessionStore = defineStore("session", {
  state: () => ({
    user: null,
    theme: "customdark",
    isSideNavCollapsed: false, // Add this line
  }),
  actions: {
    setUser(user) {
      this.user = user;
    },
    clearUser() {
      this.user = null;
    },
    setTheme(theme) {
      this.theme = theme;
    },
    setSideNavCollapsed(value) {
      // Add this method
      this.isSideNavCollapsed = value;
    },
    async fetchUser() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BACKEND_API_URL}/auth/user`,
          { withCredentials: true }
        );
        this.setUser(response.data);
        if (response.data.theme) {
          this.setTheme(response.data.theme);
        }
      } catch (error) {
        // console.error("Error fetching user:", error);
        this.clearUser();
      }
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.user,
  },
});
