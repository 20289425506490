<template>
  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 p-4">
    <div
      v-for="path in paths"
      :key="path.id"
      class="path-card bg-base-200 text-base-content hover:bg-base-100 hover:text-base-content rounded-lg shadow-md hover:shadow-lg p-4 active:outline-none active:ring-2 active:ring-secondary cursor-pointer transition duration-300 ease-in-out"
      @click="onSelectPath(path.id)"
    >
      <img
        :src="`/images/${path.image}`"
        :alt="path.title"
        class="w-full rounded-t-lg mx-auto mb-4"
      />
      <h3 class="text-xl font-semibold mb-2 text-base-content">
        {{ path.title }}
      </h3>
      <p class="text-base-content">{{ path.description }}</p>
    </div>
  </div>
</template>

<script setup>
defineProps({
  paths: Array,
});
// console.log(props);

const emits = defineEmits(["selectPath"]);

const onSelectPath = (pathId) => {
  emits("selectPath", pathId);
};
</script>

<style scoped>
/* Additional custom styles if needed */
h3 {
  font-family: "BNNaples", sans-serif;
}
</style>
