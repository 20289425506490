<template>
  <div class="favorites-overview">
    <div v-if="loading" class="text-center py-4">
      <p>Loading your favorites...</p>
    </div>
    <div
      v-else-if="sortedFavorites.length === 0"
      class="text-center py-4 text-base-content"
    >
      <p>You haven't favorited any projects yet.</p>
      <router-link
        to="/directory"
        class="mt-4 inline-block px-8 py-2 bg-neutral-focus text-base-content rounded hover:bg-base-100 transition duration-300"
      >
        View Projects
      </router-link>
    </div>
    <div
      v-else
      class="favorites-list max-w-xl lg:max-w-3xl xl:max-w-5xl mx-auto"
    >
      <!-- Header -->
      <div
        class="grid grid-cols-4 gap-4 font-bold py-2 bg-base-100 text-base-content"
      >
        <div
          v-for="column in columns"
          :key="column.key"
          class="px-4 cursor-pointer"
          @click="sort(column.key)"
        >
          {{ column.label }}
          <span class="text-3 ml-2" v-if="sortKey === column.key">{{
            sortOrder === "asc" ? "▲" : "▼"
          }}</span>
        </div>
      </div>
      <!-- Favorites -->
      <div
        v-for="project in sortedFavorites"
        :key="project.id"
        class="grid grid-cols-4 gap-4 py-2 border-b border-neutral text-base-content"
      >
        <div class="px-4">{{ project.title }}</div>
        <div class="px-4">{{ project.categories.join(", ") }}</div>
        <div class="px-4">
          <div v-for="token in project.tokens" :key="token.symbol">
            {{ token.symbol }} ({{ formatPrice(getTokenPrice(token.symbol)) }})
          </div>
        </div>
        <div class="px-2">
          <button
            @click="viewProject(project)"
            class="text-base-content hover:text-base-content transition-colors duration-200 px-2"
          >
            <font-awesome-icon :icon="['fas', 'eye']" />
          </button>
          <button
            @click="confirmDelete(project)"
            class="text-error hover:text-error-content transition-colors duration-200 px-2"
          >
            <font-awesome-icon :icon="['fas', 'trash-alt']" />
          </button>
        </div>
      </div>
    </div>

    <!-- Confirmation Modal -->
    <div
      v-if="showConfirmModal"
      class="fixed inset-0 bg-base-300 bg-opacity-80 flex items-center justify-center"
    >
      <div
        class="bg-base-200 text-base-content p-6 rounded-lg border border-base-content"
      >
        <h2 class="text-2xl font-bold text-center text-base-content mt-6 mb-6">
          Confirm Removal
        </h2>
        <p>Are you sure you want to remove this project from your favorites?</p>
        <button
          @click="removeFavorite"
          class="w-full px-4 py-2 mb-2 text-base-content rounded bg-primary hover:bg-primary-focus transition duration-300"
        >
          Confirm
        </button>
        <button
          @click="showConfirmModal = false"
          class="w-full px-4 py-2 text-base-content rounded bg-neutral-focus hover:bg-base-100 transition duration-300 mb-2"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useSessionStore } from "@/stores/session";
import { useFavoriteStore } from "@/stores/favoriteStore";
import { useDirectoryStore } from "@/stores/directoryStore";
import { useCoinsStore } from "@/stores/coins";
import { storeToRefs } from "pinia";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const router = useRouter();
const sessionStore = useSessionStore();
const favoriteStore = useFavoriteStore();
const directoryStore = useDirectoryStore();
const coinsStore = useCoinsStore();

const { favorites } = storeToRefs(favoriteStore);

const loading = ref(true);
const sortKey = ref("title");
const sortOrder = ref("asc");
const showConfirmModal = ref(false);
const projectToRemove = ref(null);

const columns = [
  { key: "title", label: "Project" },
  { key: "categories", label: "Categories" },
  { key: "tokens", label: "Tokens" },
  { key: "actions", label: "Actions" },
];

onMounted(async () => {
  if (sessionStore.isLoggedIn) {
    await Promise.all([
      favoriteStore.getUserFavorites(),
      directoryStore.fetchProjects(),
      coinsStore.fetchCoins(),
    ]);
  }
  loading.value = false;
});

const getTokenPrice = (symbol) => {
  const coin = coinsStore.coins.find((coin) => coin.symbol === symbol);
  return coin ? coin.currentPriceCoinGecko : 0;
};

const formatPrice = (price) => {
  return `$${parseFloat(price).toFixed(2)}`;
};

const sort = (key) => {
  if (sortKey.value === key) {
    sortOrder.value = sortOrder.value === "asc" ? "desc" : "asc";
  } else {
    sortKey.value = key;
    sortOrder.value = "asc";
  }
};

const sortedFavorites = computed(() => {
  const favoriteProjects = directoryStore.items.filter((project) =>
    favorites.value.includes(project.id)
  );
  return [...favoriteProjects].sort((a, b) => {
    let aValue, bValue;

    switch (sortKey.value) {
      case "categories":
        aValue = a.categories.join(", ").toLowerCase();
        bValue = b.categories.join(", ").toLowerCase();
        break;
      case "tokens":
        aValue = a.tokens[0]?.symbol.toLowerCase() || "";
        bValue = b.tokens[0]?.symbol.toLowerCase() || "";
        break;
      default:
        aValue = String(a[sortKey.value]).toLowerCase();
        bValue = String(b[sortKey.value]).toLowerCase();
    }

    if (aValue < bValue) return sortOrder.value === "asc" ? -1 : 1;
    if (aValue > bValue) return sortOrder.value === "asc" ? 1 : -1;
    return 0;
  });
});

const confirmDelete = (project) => {
  projectToRemove.value = project;
  showConfirmModal.value = true;
};

const removeFavorite = async () => {
  if (projectToRemove.value) {
    try {
      await favoriteStore.removeFavorite(projectToRemove.value.id);
      showConfirmModal.value = false;
      projectToRemove.value = null;
    } catch (error) {
      console.error("Failed to remove favorite:", error);
      // Optionally, show an error message to the user
    }
  }
};

const viewProject = (project) => {
  const projectSlug = project.title.toLowerCase().replace(/\s+/g, "-");
  router.push(`/directory?project=${projectSlug}`);
};
</script>

<style scoped>
.favorites-list {
  @apply rounded-xl;
  overflow: hidden;
}
</style>
