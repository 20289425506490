<template>
  <div class="bookmark-overview">
    <div v-if="loading" class="text-center py-4">
      <p>Loading your bookmarks...</p>
    </div>
    <div
      v-else-if="sortedBookmarks.length === 0"
      class="text-center py-4 text-base-content"
    >
      <p>You haven't bookmarked any blogs yet.</p>
      <router-link
        to="/blog"
        class="mt-4 inline-block px-8 py-2 bg-neutral-focus text-base-content rounded hover:bg-base-100 transition duration-300"
      >
        View Blogs
      </router-link>
    </div>
    <div
      v-else
      class="bookmarks-list max-w-xl lg:max-w-3xl xl:max-w-5xl mx-auto"
    >
      <!-- Header -->
      <div
        class="grid grid-cols-[2fr_1fr_1fr_1fr] gap-4 font-bold py-2 bg-base-100 text-base-content"
      >
        <div
          v-for="column in columns"
          :key="column.key"
          class="px-4 cursor-pointer"
          @click="sort(column.key)"
        >
          {{ column.label }}
          <span class="text-3 ml-2" v-if="sortKey === column.key">{{
            sortOrder === "asc" ? "▲" : "▼"
          }}</span>
        </div>
      </div>
      <!-- Bookmarks -->
      <div
        v-for="blog in sortedBookmarks"
        :key="blog.ID"
        class="grid grid-cols-[2fr_1fr_1fr_1fr] gap-4 py-2 border-b border-neutral text-base-content"
      >
        <div class="px-4">{{ blog.Title }}</div>
        <div class="px-4">{{ blog.Categories.join(", ") }}</div>
        <div class="px-4">{{ formatDate(blog.UpdatedAt) }}</div>
        <div class="px-2">
          <button
            @click="viewBlog(blog)"
            class="text-base-content hover:text-base-content transition-colors duration-200 px-2"
          >
            <font-awesome-icon :icon="['fas', 'eye']" />
          </button>
          <button
            @click="confirmDelete(blog)"
            class="text-error hover:text-error-content transition-colors duration-200 px-2"
          >
            <font-awesome-icon :icon="['fas', 'trash-alt']" />
          </button>
        </div>
      </div>
    </div>

    <!-- Confirmation Modal -->
    <div
      v-if="showConfirmModal"
      class="fixed inset-0 bg-base-300 bg-opacity-80 flex items-center justify-center"
    >
      <div
        class="bg-base-200 text-base-content p-6 rounded-lg border border-base-content"
      >
        <h2 class="text-2xl font-bold text-center text-base-content mt-6 mb-6">
          Confirm Removal
        </h2>
        <p>Are you sure you want to remove this blog from your bookmarks?</p>
        <button
          @click="removeBookmark"
          class="w-full px-4 py-2 mb-2 text-base-content rounded bg-primary hover:bg-primary-focus transition duration-300"
        >
          Confirm
        </button>
        <button
          @click="showConfirmModal = false"
          class="w-full px-4 py-2 text-base-content rounded bg-neutral-focus hover:bg-base-100 transition duration-300 mb-2"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useSessionStore } from "@/stores/session";
import { useBookmarkStore } from "@/stores/bookmarkStore";
import { useBlogStore } from "@/stores/blogStore";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const router = useRouter();
const sessionStore = useSessionStore();
const bookmarkStore = useBookmarkStore();
const blogStore = useBlogStore();

const loading = ref(true);
const showConfirmModal = ref(false);
const blogToRemove = ref(null);
const sortKey = ref("Title");
const sortOrder = ref("asc");

const columns = [
  { key: "Title", label: "Title" },
  { key: "Categories", label: "Categories" },
  { key: "UpdatedAt", label: "Updated At" },
  { key: "actions", label: "Actions" },
];

onMounted(async () => {
  if (sessionStore.isLoggedIn) {
    await Promise.all([
      bookmarkStore.getUserBookmarks(),
      blogStore.fetchBlogPosts(),
    ]);
  }
  loading.value = false;
});

const bookmarkedPosts = computed(() => {
  return blogStore.blogPosts.filter((post) =>
    bookmarkStore.isBookmarked(post.ID_Int)
  );
});

const sortedBookmarks = computed(() => {
  return [...bookmarkedPosts.value].sort((a, b) => {
    let aValue = a[sortKey.value];
    let bValue = b[sortKey.value];

    if (sortKey.value === "Categories") {
      aValue = a.Categories.join(", ").toLowerCase();
      bValue = b.Categories.join(", ").toLowerCase();
    } else if (sortKey.value === "UpdatedAt") {
      aValue = new Date(aValue);
      bValue = new Date(bValue);
    } else {
      aValue = String(aValue).toLowerCase();
      bValue = String(bValue).toLowerCase();
    }

    if (aValue < bValue) return sortOrder.value === "asc" ? -1 : 1;
    if (aValue > bValue) return sortOrder.value === "asc" ? 1 : -1;
    return 0;
  });
});

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const sort = (key) => {
  if (sortKey.value === key) {
    sortOrder.value = sortOrder.value === "asc" ? "desc" : "asc";
  } else {
    sortKey.value = key;
    sortOrder.value = "asc";
  }
};

const confirmDelete = (blog) => {
  blogToRemove.value = blog;
  showConfirmModal.value = true;
};

const removeBookmark = async () => {
  if (blogToRemove.value) {
    try {
      await bookmarkStore.removeBookmark(blogToRemove.value.ID_Int);
      showConfirmModal.value = false;
      blogToRemove.value = null;
    } catch (error) {
      console.error("Failed to remove bookmark:", error);
    }
  }
};

const viewBlog = (blog) => {
  router.push(`/blog/${blog.Slug}`);
};
</script>

<style scoped>
.bookmarks-list {
  @apply rounded-xl;
  overflow: hidden;
}
</style>
