<script setup>
import { computed } from "vue";
import siteConfig from "@/siteConfig";

const allDashboardTools = [
  {
    name: "Favorites",
    description: "View and manage your favorite projects",
    icon: "fa-solid fa-star",
    tool: "favorites",
  },
  {
    name: "Alerts",
    description: "Manage your price alerts",
    icon: "fa-solid fa-bell",
    tool: "alerts",
  },
  {
    name: "Bookmarks",
    description: "View and manage your bookmarked blogs",
    icon: "fa-solid fa-bookmark",
    tool: "bookmarks",
  },
  // Add more tools here in the future
];

const dashboardTools = computed(() => {
  return allDashboardTools.filter((tool) => siteConfig.pagesEnabled[tool.tool]);
});
</script>

<template>
  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-6">
    <div
      v-for="tool in dashboardTools"
      :key="tool.name"
      class="card bg-base-200 text-base-content border border-base-100 shadow-lg hover:bg-base-100 hover:text-base-content hover:border-primary-focus transition duration-300"
    >
      <router-link :to="{ name: 'Dashboard', params: { tool: tool.tool } }">
        <div class="card-body">
          <h2 class="card-title">
            <font-awesome-icon :icon="tool.icon" class="mr-2" />
            {{ tool.name }}
          </h2>
          <p>{{ tool.description }}</p>
        </div>
      </router-link>
    </div>
  </div>
</template>
