<script setup>
import { computed, markRaw } from "vue";
import { useRoute } from "vue-router";
import { useSessionStore } from "@/stores/session";
import DashboardOverview from "@/components/dashboard/DashboardOverview.vue";
import BookmarksOverview from "@/components/dashboard/BookmarksOverview.vue";
import AlertsOverview from "@/components/dashboard/AlertsOverview.vue";
import FavoritesOverview from "@/components/dashboard/FavoritesOverview.vue";
import { useHead } from "@vueuse/head";
import { usePageData } from "@/utilities/general/usePageData";
const { pageContent, headData } = usePageData("dashboard");
useHead(headData);

const route = useRoute();
// const router = useRouter();
const sessionStore = useSessionStore();

const toolComponents = {
  overview: markRaw(DashboardOverview),
  favorites: markRaw(FavoritesOverview),
  alerts: markRaw(AlertsOverview),
  bookmarks: markRaw(BookmarksOverview),
  // Add more tools here as needed
};

const currentTool = computed(() => route.params.tool || "overview");

const pageTitle = computed(() => {
  switch (currentTool.value) {
    case "overview":
      return pageContent?.content?.title ?? "Dashboard";
    case "bookmarks":
      return "Bookmarks";
    case "alerts":
      return "Alerts";
    case "favorites":
      return "Favorites";
    default:
      return "Dashboard";
  }
});

const isLoggedIn = computed(() => sessionStore.isLoggedIn);
</script>

<template>
  <div
    class="container mx-auto py-4 px-8 w-full mt-24 md:mt-46 overflow-x-hidden"
    style="min-height: 100vh"
  >
    <div v-if="isLoggedIn">
      <div class="text-center mb-8 p-0 overflow-x-hidden">
        <h1 class="text-4xl lg:text-6xl text-base-content">
          {{ pageTitle }}
        </h1>
      </div>

      <component
        :is="toolComponents[currentTool] || toolComponents.overview"
      ></component>
    </div>

    <div v-else class="text-center text-base-content">
      <p>You are not logged in.</p>
      <button
        class="mt-4 px-8 py-2 bg-neutral-focus text-base-content rounded hover:bg-base-100 transition duration-300"
      >
        <router-link :to="'/login'"> Login </router-link>
      </button>
    </div>
  </div>
</template>
