<template>
  <div class="alerts-overview">
    <div v-if="loading" class="text-center py-4">
      <p>Loading your alerts...</p>
    </div>
    <div
      v-else-if="sortedAlerts.length === 0"
      class="text-center py-4 text-base-content"
    >
      <p>You don't have any alerts set up yet.</p>
      <router-link
        to="/directory"
        class="mt-4 inline-block px-8 py-2 bg-neutral-focus text-base-content rounded hover:bg-base-100 transition duration-300"
      >
        View Projects
      </router-link>
    </div>
    <div v-else class="alerts-list max-w-xl lg:max-w-3xl xl:max-w-5xl mx-auto">
      <!-- Header -->
      <div
        class="grid grid-cols-5 gap-4 font-bold py-2 bg-base-100 text-base-content"
      >
        <div
          v-for="column in columns"
          :key="column.key"
          class="px-4 cursor-pointer"
          @click="sort(column.key)"
        >
          {{ column.label }}
          <span class="text-3 ml-2" v-if="sortKey === column.key">{{
            sortOrder === "asc" ? "▲" : "▼"
          }}</span>
        </div>
      </div>
      <!-- Alerts -->
      <div
        v-for="alert in sortedAlerts"
        :key="alert._id"
        class="grid grid-cols-5 gap-4 py-2 border-b border-neutral text-base-content"
      >
        <div class="px-4">{{ getProjectName(alert.projectId) }}</div>
        <div class="px-4">
          {{ alert.type === "priceTarget" ? "Price Target" : "Price Movement" }}
        </div>
        <div class="px-4">
          {{
            alert.type === "priceTarget"
              ? formatPrice(alert.price)
              : alert.price + "%"
          }}
        </div>
        <div class="px-4">
          {{ alert.frequency === "everyTime" ? "Every Time" : "One Time" }}
        </div>
        <div class="px-3">
          <button
            @click="openEditModal(alert)"
            class="text-base-content hover:text-base-content transition-colors duration-200 px-2"
          >
            <font-awesome-icon :icon="['fas', 'edit']" />
          </button>
          <button
            @click="confirmDelete(alert)"
            class="text-error hover:text-error-content transition-colors duration-200 px-2"
          >
            <font-awesome-icon :icon="['fas', 'trash-alt']" />
          </button>
        </div>
      </div>
    </div>

    <!-- Confirmation Modal -->
    <div
      v-if="showConfirmModal"
      class="fixed inset-0 bg-base-300 bg-opacity-80 flex items-center justify-center"
    >
      <div
        class="bg-base-200 text-base-content p-6 rounded-lg border border-base-content"
      >
        <h2 class="text-2xl font-bold text-center text-base-content mt-6 mb-6">
          Confirm Deletion
        </h2>
        <p>Are you sure you want to delete this alert?</p>
        <button
          @click="deleteAlert"
          class="w-full px-4 py-2 mb-2 text-base-content rounded bg-primary hover:bg-primary-focus transition duration-300"
        >
          Confirm
        </button>
        <button
          @click="showConfirmModal = false"
          class="w-full px-4 py-2 text-base-content rounded bg-neutral-focus hover:bg-base-100 transition duration-300 mb-2"
        >
          Cancel
        </button>
      </div>
    </div>

    <!-- Edit Alert Modal -->
    <EditAlertModal
      v-if="showEditModal"
      :alert="selectedAlert"
      @close-modal="closeEditModal"
      @edit-alert="handleEditAlert"
    />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useSessionStore } from "@/stores/session";
import { useAlertStore } from "@/stores/alertStore";
import { useDirectoryStore } from "@/stores/directoryStore";
import { storeToRefs } from "pinia";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import EditAlertModal from "./EditAlertModal.vue";

const sessionStore = useSessionStore();
const alertStore = useAlertStore();
const directoryStore = useDirectoryStore();

const { alerts } = storeToRefs(alertStore);

const loading = ref(true);
const sortKey = ref("projectId");
const sortOrder = ref("asc");
const showConfirmModal = ref(false);
const alertToDelete = ref(null);
const showEditModal = ref(false);
const selectedAlert = ref(null);

const columns = [
  { key: "projectId", label: "Project" },
  { key: "type", label: "Type" },
  { key: "price", label: "Target" },
  { key: "frequency", label: "Frequency" },
  { key: "actions", label: "Actions" },
];

onMounted(async () => {
  if (sessionStore.isLoggedIn) {
    await Promise.all([
      alertStore.getUserAlerts(),
      directoryStore.fetchProjects(),
    ]);
  }
  loading.value = false;
});

const getProjectName = (projectId) => {
  const project = directoryStore.items.find((item) => item.id === projectId);
  return project ? project.title : `Project ${projectId}`;
};

const formatPrice = (price) => {
  return `$${parseFloat(price).toFixed(2)}`;
};

const sort = (key) => {
  if (sortKey.value === key) {
    sortOrder.value = sortOrder.value === "asc" ? "desc" : "asc";
  } else {
    sortKey.value = key;
    sortOrder.value = "asc";
  }
};

const sortedAlerts = computed(() => {
  return [...alerts.value].sort((a, b) => {
    let aValue = a[sortKey.value];
    let bValue = b[sortKey.value];

    if (sortKey.value === "projectId") {
      aValue = getProjectName(a.projectId).toLowerCase();
      bValue = getProjectName(b.projectId).toLowerCase();
    }

    if (aValue < bValue) return sortOrder.value === "asc" ? -1 : 1;
    if (aValue > bValue) return sortOrder.value === "asc" ? 1 : -1;
    return 0;
  });
});

const confirmDelete = (alert) => {
  alertToDelete.value = alert;
  showConfirmModal.value = true;
};

const deleteAlert = async () => {
  if (alertToDelete.value) {
    try {
      // Use the correct ID property (alertId) when calling removeAlert
      await alertStore.removeAlert(alertToDelete.value.alertId);
      showConfirmModal.value = false;
      alertToDelete.value = null;
    } catch (error) {
      console.error("Failed to remove alert:", error);
      // Optionally, show an error message to the user
    }
  }
};

const openEditModal = (alert) => {
  selectedAlert.value = alert;
  showEditModal.value = true;
};

const closeEditModal = () => {
  showEditModal.value = false;
  selectedAlert.value = null;
};

const handleEditAlert = async (editedAlert) => {
  try {
    await alertStore.editAlert(editedAlert);
    closeEditModal();
  } catch (error) {
    console.error("Failed to edit alert:", error);
    // Optionally, show an error message to the user
  }
};
</script>

<style scoped>
.alerts-list {
  @apply rounded-xl;
  overflow: hidden;
}
</style>
