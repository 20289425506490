<script setup>
import { computed } from "vue";
import { renderContent } from "@/utilities/general/renderContent";
import { useHead } from "@vueuse/head";
import { usePageData } from "@/utilities/general/usePageData";
const { pageContent, headData } = usePageData("about");
useHead(headData);
const renderedContent = computed(() => {
  if (pageContent.value && pageContent.value.content) {
    const { requiresRenderer, body } = pageContent.value.content;

    if (requiresRenderer && body) {
      return renderContent(body);
    }
  }
  return [];
});
</script>

<template>
  <div
    v-if="pageContent"
    class="relative container mx-auto max-w-screen-xl text-center"
  >
    <h1
      class="text-4xl lg:text-6xl font-bold max-w-2xl mx-auto mt-20 md:mt-42 mb-12 text-base-content text-center"
    >
      {{ pageContent.content.title ?? "About Us" }}
    </h1>
    <div class="max-w-lg mx-auto text-base-content text-left">
      <component
        v-for="(element, index) in renderedContent"
        :key="index"
        :is="element"
      />
    </div>
    <router-link
      to="/faq"
      class="inline-block mt-12 px-6 py-3 text-base-content rounded bg-neutral-focus hover:bg-base-100 transition duration-300"
    >
      FAQs
    </router-link>
    <div class="emptySpace mt-0 lg:mt-32"></div>
  </div>
  <div v-else>
    Loading...
    <pre>{{ JSON.stringify(siteDataStore.pagesData, null, 2) }}</pre>
  </div>
</template>

<style scoped>
:deep(.body-link) {
  @apply text-secondary-focus hover:text-secondary-focus;
}

:deep(p) {
  @apply text-lg mb-6;
}
</style>
