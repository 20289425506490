<!-- src/components/SayThanks.vue -->
<template>
  <div class="say-thanks-container text-base-content">
    <div class="content-wrapper">
      <h2
        v-if="!showTipSuccess && isLoggedIn && hasWallet"
        class="text-xl font-semibold text-base-content no-top-line"
      >
        Say Thanks
      </h2>
      <p
        v-if="!showTipSuccess && isLoggedIn && hasWallet"
        class="text-base-content"
      >
        Support the author of this blog directly with a tip.
      </p>
      <div v-if="!isLoggedIn" class="text-base-content">
        <p>Please log in to say thanks.</p>
      </div>
      <div v-else-if="!hasWallet" class="text-base-content">
        <p>Please connect a wallet to say thanks.</p>
      </div>
      <div v-else class="mt-8 adjusted-height">
        <TipSuccess v-if="showTipSuccess" :txid="transactionID" />
        <div v-else>
          <!-- Currency Selector Section -->
          <div class="currency-selector">
            <div
              v-for="currency in currencies"
              :key="currency"
              class="rounded-lg input input-bordered text-base-content bg-base-100"
              :class="{
                'currency-option': true,
                active: activeCurrency.symbol === currency,
              }"
              @click="setActiveCurrency(currency)"
            >
              {{ currency }}
            </div>
          </div>
          <!-- Tip Amount Buttons Section -->
          <div class="tip-amounts">
            <button
              v-for="(item, index) in buttonValues"
              :key="index"
              class="tip-button rounded-lg input input-bordered text-base-content bg-base-100"
              @click="setAmount(item.value)"
            >
              {{ item.display }}
            </button>
          </div>
          <!-- Custom Amount Input Field -->
          <div
            class="custom-amount-container input input-bordered w-full mt-2 text-base-content bg-base-100 flex items-center"
          >
            <input
              type="text"
              v-model="customAmount"
              class="bg-base-100 text-base-content placeholder-base-content"
              placeholder="Enter amount"
            />
            <div
              :class="{
                'flex items-center': true,
                'mr-4 -ml-4':
                  activeCurrency.symbol !== 'BONK' &&
                  activeCurrency.symbol !== 'USDC',
                'mr-8 -ml-8':
                  activeCurrency.symbol === 'BONK' ||
                  activeCurrency.symbol === 'USDC',
              }"
            >
              <img
                :src="activeCurrency.imagePath"
                class="currency-logo w-5 h-5 mr-2"
                alt="Currency Logo"
              />
              <span class="">{{ activeCurrency.symbol }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="toc-button">
      <div
        v-if="!isLoggedIn"
        class="flex justify-center items-center bg-primary hover:bg-primary-focus transition duration-300 rounded-lg say-thanks-button mt-4"
        @click="login"
      >
        <div class="flex items-center">
          <p class="text-sm text-base-content m-0 py-1 font-bold">
            LOGIN TO CONTINUE
          </p>
        </div>
      </div>
      <div
        v-else-if="!hasWallet"
        class="flex justify-center items-center transition duration-300 rounded-lg say-thanks-button mt-4"
      >
        <wallet-multi-button dark></wallet-multi-button>
      </div>
      <div
        v-else-if="!showTipSuccess && !isProcessing"
        class="flex justify-center items-center bg-primary hover:bg-primary-focus rounded-lg say-thanks-button mt-4"
        @click="processTip(customAmount, activeCurrency)"
      >
        <div class="flex items-center">
          <p
            class="text-sm text-base-content hover:text-base-content m-0 py-1 font-bold"
          >
            CONTINUE
          </p>
        </div>
      </div>
      <div
        v-else-if="!showTipSuccess && isProcessing"
        class="flex justify-center items-center bg-base-300 hover:bg-base-300 rounded-lg say-thanks-button mt-4"
      >
        <div class="flex items-center py-1">
          <div role="status">
            <svg
              aria-hidden="true"
              class="inline w-5 h-5 text-base-content animate-spin dark:text-neutral-focus fill-neutral-focus dark:fill-base-content"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          </div>
        </div>
      </div>
      <div
        v-if="!showTipSuccess"
        class="flex justify-center items-center bg-neutral hover:bg-neutral-focus transition duration-300 rounded-lg mt-2"
        @click="cancel"
      >
        <div class="flex items-center">
          <p
            class="text-sm text-base-content hover:text-base-content m-0 py-1 font-bold"
          >
            CANCEL
          </p>
        </div>
      </div>
      <div
        v-if="showTipSuccess && !isProcessing"
        class="flex justify-center items-center bg-neutral hover:bg-neutral-focus rounded-lg mt-2"
        @click="cancel"
      >
        <div class="flex items-center">
          <p
            class="text-sm text-base-content hover:text-base-content m-0 py-1 font-bold"
          >
            CLOSE
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from "vue";
import { toast } from "vue3-toastify";
import { useWallet } from "solana-wallets-vue";
import {
  Connection,
  PublicKey,
  Transaction,
  SystemProgram,
  LAMPORTS_PER_SOL,
} from "@solana/web3.js";
import { TOKEN_PROGRAM_ID, createTransferInstruction } from "@solana/spl-token";
import TipSuccess from "./TipSuccess.vue";
import { useSessionStore } from "@/stores/session";
import { WalletMultiButton } from "solana-wallets-vue";
import eventBus from "@/eventBus";

const session = useSessionStore();
const { publicKey, sendTransaction, connected } = useWallet();

const customAmount = ref("");
const isProcessing = ref(false);
const transactionID = ref(null);
const showTipSuccess = ref(false);
const transactionComplete = ref(false);

// Define currencies and active currency state
const currencies = ["SOL", "USDC", "BONK"];

const USDC_MINT_ADDRESS = "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v";
const BONK_MINT_ADDRESS = "DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263";

const SOL_IMAGE_PATH = "/images/coins/solana.webp";
const USDC_IMAGE_PATH = "/images/coins/usdc.webp";
const BONK_IMAGE_PATH = "/images/coins/bonk.webp";

const currencyDetails = ref([
  { symbol: "SOL", imagePath: SOL_IMAGE_PATH },
  { symbol: "USDC", imagePath: USDC_IMAGE_PATH },
  { symbol: "BONK", imagePath: BONK_IMAGE_PATH },
]);

const activeCurrency = ref(currencyDetails.value[0]);

const isLoggedIn = computed(() => !!session.user);
const hasWallet = computed(() => connected.value && !!publicKey.value);

// Function to update the active currency
const setActiveCurrency = (currencySymbol) => {
  const currency = currencyDetails.value.find(
    (c) => c.symbol === currencySymbol
  );
  if (currency) {
    activeCurrency.value = currency;
  } else {
    console.error(`Currency symbol not found: ${currencySymbol}`);
  }
};

const buttonValues = computed(() => {
  switch (activeCurrency.value.symbol) {
    case "SOL":
      return [
        { display: "0.001", value: 0.001 },
        { display: "0.01", value: 0.01 },
        { display: "0.1", value: 0.1 },
      ];
    case "USDC":
      return [
        { display: "0.01", value: 0.01 },
        { display: "0.1", value: 0.1 },
        { display: "1", value: 1 },
        { display: "10", value: 10 },
      ];
    case "BONK":
      return [
        { display: "100", value: 100 },
        { display: "1K", value: 1000 },
        { display: "10K", value: 10000 },
        { display: "100K", value: 100000 },
      ];
    default:
      return [];
  }
});

const setAmount = (value) => {
  customAmount.value = value.toString();
};

const sendTip = async (amount, currency) => {
  if (!publicKey.value) {
    console.log("Wallet not connected");
    throw new Error("Wallet not connected");
  }

  console.log(`Sending ${amount} ${currency.symbol} to ${publicKey.value}`);

  const connection = new Connection(
    process.env.VUE_APP_SOLANA_RPC,
    "confirmed"
  );

  const receiverPublicKey = new PublicKey(
    "ApMSwAu1i1Jx6aGGVsLihK7MR4NQ4szwMmMVM5VFCxYs"
  );

  try {
    const transaction = new Transaction();

    if (currency.symbol === "SOL") {
      const lamports = Math.round(amount * LAMPORTS_PER_SOL);
      transaction.add(
        SystemProgram.transfer({
          fromPubkey: publicKey.value,
          toPubkey: receiverPublicKey,
          lamports,
        })
      );
    } else {
      const decimals = currency.symbol === "USDC" ? 6 : 5;
      const tokenAmount = Math.round(amount * Math.pow(10, decimals));
      const mintAddress =
        currency.symbol === "USDC" ? USDC_MINT_ADDRESS : BONK_MINT_ADDRESS;
      const senderTokenAccountAddress = await findAssociatedTokenAddress(
        publicKey.value,
        new PublicKey(mintAddress)
      );
      const receiverTokenAccountAddress = await findAssociatedTokenAddress(
        receiverPublicKey,
        new PublicKey(mintAddress)
      );

      transaction.add(
        createTransferInstruction(
          senderTokenAccountAddress,
          receiverTokenAccountAddress,
          publicKey.value,
          tokenAmount,
          [],
          TOKEN_PROGRAM_ID
        )
      );
    }

    const latestBlockhash = await connection.getLatestBlockhash();
    transaction.recentBlockhash = latestBlockhash.blockhash;
    transaction.feePayer = publicKey.value;

    const txid = await sendTransaction(transaction, connection);

    console.log(`Transaction sent. TxID: ${txid}`);
    return txid;
  } catch (error) {
    console.error("Error sending transaction:", error);
    throw error;
  }
};

// Helper function to find an associated token address
async function findAssociatedTokenAddress(walletAddress, tokenMintAddress) {
  return PublicKey.findProgramAddressSync(
    [
      walletAddress.toBuffer(),
      TOKEN_PROGRAM_ID.toBuffer(),
      tokenMintAddress.toBuffer(),
    ],
    new PublicKey("ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL")
  )[0];
}

async function processTip(amount, currency) {
  // Check if amount is empty or not a number
  if (!amount || isNaN(parseFloat(amount))) {
    toast.error("Please enter a valid amount.", {
      theme: "dark",
    });
    return;
  }

  isProcessing.value = true;
  transactionComplete.value = false;

  try {
    const parsedAmount = parseFloat(amount);
    if (parsedAmount <= 0) {
      throw new Error("Amount must be greater than 0.");
    }

    const txid = await sendTip(parsedAmount, currency);
    if (txid) {
      // Poll the transaction status until it's confirmed
      let status = await checkTransactionStatus(txid);
      let attempts = 0;
      while (status !== "confirmed" && attempts < 10) {
        await new Promise((resolve) => setTimeout(resolve, 3000));
        status = await checkTransactionStatus(txid);
        attempts++;
      }

      if (status === "confirmed") {
        transactionID.value = txid;
        showTipSuccess.value = true;
        setTimeout(() => {
          showTipSuccess.value = false;
          cancel();
        }, 45000);
        toast.success("Tip sent and confirmed.", {
          autoClose: 10000,
          theme: "dark",
        });
        transactionComplete.value = true;
      } else {
        throw new Error(
          "Transaction confirmation failed after multiple attempts."
        );
      }
    } else {
      throw new Error("Failed to send tip. No TXID returned.");
    }
  } catch (error) {
    console.error("Transaction Error:", error);
    toast.error(error.message || "Failed to send tip. Please try again.", {
      theme: "dark",
    });
  } finally {
    isProcessing.value = false;
  }
}

async function checkTransactionStatus(txid) {
  const connection = new Connection(
    process.env.VUE_APP_SOLANA_RPC,
    "confirmed"
  );
  try {
    const status = await connection.getSignatureStatuses([txid]);
    const result = status && status.value[0];
    if (result) {
      if (
        result.confirmationStatus === "processed" ||
        result.confirmationStatus === "confirmed" ||
        result.confirmationStatus === "finalized"
      ) {
        return "confirmed";
      } else {
        return "pending";
      }
    }
    return "unknown";
  } catch (error) {
    console.error("Error checking transaction status:", error);
    return "error";
  }
}

const emit = defineEmits(["close"]);

const cancel = () => {
  emit("close");
};

const login = () => {
  eventBus.emit("showLogin");
};

// Watch for changes in wallet connection
watch(connected, (newConnected) => {
  if (newConnected && isLoggedIn.value) {
    console.log("Wallet connected:", publicKey.value.toString());
  }
});

onMounted(async () => {
  // Fetch the user's session
  if (!session.user) {
    await session.fetchUser();
  }

  // No need to initialize wallet connection here,
  // as it's handled by the Solana wallet adapter

  console.log("SayThanks component initialized successfully");
});
</script>

<style scoped>
.say-thanks-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* This will push the button to the bottom */
  height: 100%; /* Ensure it fills its parent's height, adjust as necessary */
  color: white;
  z-index: 100;
}

.content-wrapper {
  flex-grow: 1;
}

.currency-selector {
  display: flex;
  justify-content: center;
  gap: 4px; /* Adjust the gap between currency options as needed */
  margin-top: 20px; /* Spacing above the currency selector */
}

.currency-option {
  padding: 10px 20px; /* Adjust padding as needed */
  cursor: pointer;
}

.currency-option.active {
  background-color: #78716c; /* Active option background color */
  color: #f5f5f4; /* Active option text color */
}

.tip-amounts {
  display: flex;
  justify-content: center;
  gap: 4px; /* Adjust the gap between buttons as needed */
  margin-top: 20px; /* Spacing above the buttons */
}

.tip-button {
  cursor: pointer;
}

.currency-logo {
  width: 20px; /* Adjust as needed */
  height: 20px; /* Adjust as needed */
  margin-right: 5px; /* Adjust spacing between the logo and the symbol */
}

.custom-amount-container {
  justify-content: flex-start;
  align-items: center;
  gap: 0px; /* Adjust the gap between the input field and the currency display */
  margin-top: 20px; /* Spacing above the custom amount container */
}

.toc-button {
  cursor: pointer;
  position: relative;
  bottom: 0;
}

@media (min-width: 1400px) and (max-height: 1000px) {
  .adjusted-height {
    margin-top: 12px;
  }
  .tip-amounts {
    margin-top: 8px;
  }
  .custom-amount-container {
    margin-top: 8px;
  }
}
</style>
