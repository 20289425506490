<template>
  <div class="airdrop-card bg-base-200 shadow-md">
    <div class="flex flex-row w-full items-center mb-6">
      <div class="flex justify-start flex-grow">
        <img class="item-image" :src="item.image" alt="item.title" />
        <h3 class="item-title">{{ item.title }}</h3>
      </div>
      <div class="flex justify-end">
        <div
          :class="[
            'status-box',
            'text-base-content',
            'rounded-md',
            'w-12',
            'h-12',
            'p-2',
            'justify-center',
            'items-center',
            statusClass,
          ]"
        >
          <p
            class="text-base-content m-0 p-0 mt-1 status-symbol"
            v-html="statusSymbol"
          ></p>
        </div>
      </div>
    </div>
    <p class="text-base-content">{{ item.slogan }}</p>
    <!-- Add the category -->
    <div
      class="item-category bg-primary text-base-content w-24 rounded-full text-center align-middle px-1"
    >
      {{ item.category }}
    </div>
    <!-- Add more fields as required -->
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    statusClass() {
      switch (this.item.status) {
        case "active":
          return "bg-green-500";
        case "upcoming":
          return "bg-yellow-500";
        case "rumored":
          return "bg-orange-500";
        case "finished":
          return "bg-gray-500";
        default:
          return "bg-neutral-focus";
      }
    },
    statusSymbol() {
      switch (this.item.status) {
        case "active":
        case "upcoming":
          return "✔"; // Checkmark
        case "rumored":
          return "?"; // Question mark
        case "finished":
          return "✖"; // X symbol
        default:
          return "";
      }
    },
  },
};
</script>

<style scoped>
.airdrop-card {
  /* Add your card styling here */
  @apply text-base-content border-2 border-neutral-focus hover:border-base-content active:outline-none active:ring-1 active:ring-secondary;
  padding: 20px;
  border-radius: 8px;
  margin: 10px;
  /* more styles... */
}

.item-image {
  /* Add your image styling here */
  width: 35px;
  height: 35px;
  margin-right: 10px;
}

.item-title {
  /* Add your heading styling here */
  @apply text-base-content text-lg;
  font-size: 1.2rem;
  font-family: Lato-Bold;
  margin-top: 2px;
}

.status-symbol {
  /* Add your data square text styling here */
  font-size: 1.2rem;
  line-height: 1.4rem;
  padding-left: 8px;
  font-family: BNNaples;
}

.data-square-title {
  /* Add your data square title styling here */
  font-size: 0.6rem;
  font-family: Lato-Regular;
}
</style>
